import { Vault } from '../models/vault.type';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject, signal } from '@angular/core';
import { VaultsService } from '../services/vaults.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Observable, of, pipe } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { GetVaultsPagingOptionsType } from '../models/get-vaults-paging-options.type';
import { tapResponse } from '@ngrx/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateVaultResponseDto } from '../models/create-vault-response.dto';
import {
  removeAllEntities,
  removeEntity,
  setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  CurrentTenantChange,
  TenantActionsTypes,
} from '../../tenants/store/tenant.actions';

export type PopulatedVault = Vault & {
  populatedVault?: CreateVaultResponseDto;
};

type VaultsState = {
  gettingTenantsVaults: boolean;
  gettingUserVaults: boolean;
  hasMore: boolean;
  nextPage: string;
  previousPage: string;
  tenantId: string;
  totalCount: number;
};

const initialState: VaultsState = {
  gettingTenantsVaults: false,
  gettingUserVaults: false,
  hasMore: false,
  nextPage: null,
  previousPage: null,
  tenantId: null,
  totalCount: 0,
};

export const VaultsGlobalStore = signalStore(
  withDevtools('vaults'),
  withEntities<PopulatedVault>(),
  withState<VaultsState>(initialState),
  withComputed(({ entities }) => ({
    vaultsList: computed(() =>
      entities().map(vault => ({
        ...vault,
      }))
    ),
  })),
  withMethods(store => ({
    addOrUpdateVault(vault: Vault | PopulatedVault): void {
      if (store.entityMap()[vault.id]) {
        patchState(
          store,
          updateEntity({ id: vault.id, changes: { ...vault } })
        );
      } else {
        patchState(
          store,
          setEntity({
            ...vault,
          })
        );
      }
    },
    removeVault(vaultId: string): void {
      patchState(store, removeEntity(vaultId));
    },
    clear(): void {
      patchState(store, initialState);
      patchState(store, removeAllEntities());
    },
  })),
  withMethods(store => ({
    getVaultById(vaultId: string) {
      return computed(() => store.entityMap()[vaultId]);
    },
    getVaultsByOpenId(openId: string) {
      return computed(() =>
        store.vaultsList().find(vault => vault.openId === openId)
      );
    },
    getVaultsByInvitationCode(invitationCode: string) {
      return computed(() =>
        store
          .vaultsList()
          .find(vault => vault.invitationCode === invitationCode)
      );
    },
    getVaultsByFederationId(federationId: string) {
      return computed(() =>
        store.vaultsList().find(vault => vault.federationId === federationId)
      );
    },
  })),
  withMethods(store => ({
    getUserVaults(payload: {
      openId?: string;
      federationId?: string;
      invitationCode?: string;
    }) {
      const { openId, invitationCode, federationId } = payload;
      if (openId) {
        return store.getVaultsByOpenId(openId);
      }
      if (invitationCode) {
        return store.getVaultsByInvitationCode(invitationCode);
      }
      if (federationId) {
        return store.getVaultsByFederationId(federationId);
      }
      return signal(null);
    },
  })),
  withMethods((store, service = inject(VaultsService)) => {
    const requestTenantVaults = rxMethod<{
      tenantId: string;
      options?: GetVaultsPagingOptionsType;
      callback?: (error?: HttpErrorResponse) => void;
    }>(
      pipe(
        tap(() => patchState(store, { gettingTenantsVaults: true })),
        switchMap(({ tenantId, options, callback }) =>
          service.getTenantVaults(tenantId, options).pipe(
            tapResponse(
              response => {
                const { data, nextPage, previousPage, hasMore, totalCount } =
                  response;
                patchState(store, {
                  gettingTenantsVaults: false,
                  nextPage,
                  previousPage,
                  hasMore,
                  totalCount,
                  tenantId,
                });
                data.forEach(vault => store.addOrUpdateVault(vault));
                if (callback) {
                  callback(null);
                }
              },
              (error: HttpErrorResponse) => {
                patchState(store, {
                  gettingTenantsVaults: false,
                });
                if (callback) {
                  callback(error);
                }
              }
            )
          )
        )
      )
    );
    const requestTenantVaultsNextPage = (
      callback?: (error?: HttpErrorResponse) => void
    ) => {
      if (store.hasMore()) {
        return requestTenantVaults({
          tenantId: store.tenantId(),
          options: { startingAfter: store.nextPage() },
          callback,
        });
      }
    };
    const requestUserVault = rxMethod<{
      tenantId: string;
      openId?: string;
      invitationCode?: string;
      federationId?: string;
      callback?: (error?: HttpErrorResponse) => void;
    }>(
      pipe(
        tap(() => patchState(store, { gettingUserVaults: true })),
        switchMap(
          ({ tenantId, invitationCode, openId, federationId, callback }) => {
            let request: Observable<Vault>;
            if (!openId && !invitationCode && !federationId) {
              patchState(store, { gettingUserVaults: false });
              if (callback) callback(null);
              return of(null);
            }
            if (openId) {
              request = service.getTenantVaultByOpenId(tenantId, openId);
            }
            if (invitationCode) {
              request = service.getTenantVaultByInvitationCode(
                tenantId,
                invitationCode
              );
            }
            if (federationId) {
              request = service.getTenantVaultByFederationId(
                tenantId,
                federationId
              );
            }
            return request.pipe(
              tapResponse(
                vault => {
                  store.addOrUpdateVault({
                    ...vault,
                    id: vault.id ?? vault._id,
                  });
                  patchState(store, { gettingUserVaults: false });
                  if (callback) callback(null);
                },
                (error: HttpErrorResponse) => {
                  patchState(store, { gettingUserVaults: false });
                  if (callback) callback(error);
                }
              )
            );
          }
        )
      )
    );
    const requestVaultById = rxMethod<{
      tenantId: string;
      vaultId: string;
      callback?: (error?: HttpErrorResponse) => void;
    }>(
      pipe(
        tap(() => patchState(store, { gettingUserVaults: true })),
        switchMap(({ tenantId, vaultId, callback }) =>
          service.getTenantVaultById(tenantId, vaultId).pipe(
            tapResponse(
              vault => {
                const {
                  _id,
                  id,
                  name,
                  filingCabinetId,
                  tenant,
                  cobrandId,
                  createdAt,
                  updatedAt,
                  active,
                  openId,
                  invitationCode,
                  federationId,
                } = vault;
                store.addOrUpdateVault({
                  id: _id ?? id,
                  name,
                  filingCabinetId,
                  tenant,
                  cobrandId,
                  createdAt,
                  updatedAt,
                  active,
                  openId,
                  invitationCode,
                  federationId,
                  populatedVault: vault,
                });
                patchState(store, { gettingUserVaults: false });
                if (callback) callback(null);
              },
              (error: HttpErrorResponse) => {
                patchState(store, { gettingUserVaults: false });
                if (callback) callback(error);
              }
            )
          )
        )
      )
    );
    return {
      requestTenantVaults,
      requestTenantVaultsNextPage,
      requestUserVault,
      requestVaultById,
    };
  }),
  withHooks({
    onInit(store, action$ = inject(Actions)) {
      action$
        .pipe(
          takeUntilDestroyed(),
          ofType<CurrentTenantChange>(TenantActionsTypes.CurrentTenantChange),
          tap(() => store.clear())
        )
        .subscribe();
    },
  })
);
