import { inject, Injectable } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CreateVaultDto } from '../models/create-vault.dto';
import { CreateVaultResponseDto } from '../models/create-vault-response.dto';
import { GetVaultsPagingOptionsType } from '../models/get-vaults-paging-options.type';
import { PaginatorHelper } from '../../core/helpers/paginator.helper';
import { Vault } from '../models/vault.type';
import { SideDrawerVaultDto } from '../models/side-drawer-vault.dto';
import { PaginatorApiResponse } from '../../sidedrawers/models/paginator-api-response.model';
import { VaultSideDrawer } from '../models/vault-side-drawer.type';
import { CreateSideDrawerInVaultResponseDto } from '../models/create-side-drawer-in-vault-response.dto';

@Injectable()
export class VaultsService {
  private readonly authService = inject(AuthService);
  private readonly http = inject(HttpClient);

  createTenantVault(
    tenantId: string,
    dto: CreateVaultDto
  ): Observable<CreateVaultResponseDto> {
    return this.http.post<CreateVaultResponseDto>(
      environment.tenantApi + `tenant/tenant-id/${tenantId}/vaults`,
      dto,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getTenantVaults(
    tenantId: string,
    options: GetVaultsPagingOptionsType
  ): Observable<PaginatorApiResponse<Vault>> {
    return this.http.get<PaginatorApiResponse<Vault>>(
      this.generatePagingVaultUrl(
        environment.tenantApi + `tenant/tenant-id/${tenantId}/vaults`,
        options
      ),
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  updateTenantVault(
    tenantId: string,
    vaultId: string,
    name: string
  ): Observable<Vault> {
    return this.http.put<Vault>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/vaults/vault-id/${vaultId}`,
      { name },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getTenantVaultById(
    tenantId: string,
    vaultId: string
  ): Observable<CreateVaultResponseDto> {
    return this.http.get<CreateVaultResponseDto>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/vaults/vault-id/${vaultId}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getTenantVaultByOpenId(tenantId: string, openId: string): Observable<Vault> {
    return this.http.get<Vault>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/users/open-id/${openId}/vaults`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getTenantVaultByInvitationCode(
    tenantId: string,
    invitationCode: string
  ): Observable<Vault> {
    return this.http.get<Vault>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/users/invitations/invitation-code/${invitationCode}/vaults`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getTenantVaultByFederationId(
    tenantId: string,
    federationId: string
  ): Observable<Vault> {
    return this.http.get<Vault>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/users/federation-id/${federationId}/vaults`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  deleteTenantVaultById(tenantId: string, vaultId: string): Observable<Vault> {
    return this.http.delete<Vault>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/vaults/vault-id/${vaultId}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  assignSideDrawerToTenantVault(
    tenantId: string,
    vaultId: string,
    sidedrawerId: string
  ): Observable<Vault> {
    return this.http.post<Vault>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/vaults/vault-id/${vaultId}`,
      { sidedrawerId },
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  createSideDrawerInTenantVault(
    tenantId: string,
    vaultId: string,
    dto: SideDrawerVaultDto
  ): Observable<CreateSideDrawerInVaultResponseDto> {
    return this.http.post<CreateSideDrawerInVaultResponseDto>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/vaults/vault-id/${vaultId}/sidedrawers`,
      dto,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  deleteSideDrawerInTenantVault(
    tenantId: string,
    vaultId: string,
    sideDrawerId: string
  ): Observable<Vault> {
    return this.http.delete<Vault>(
      environment.tenantApi +
        `tenant/tenant-id/${tenantId}/vaults/vault-id/${vaultId}/sidedrawers/sidedrawer-id/${sideDrawerId}`,
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  getSideDrawersInTenantVault(
    tenantId: string,
    vaultId: string,
    options: GetVaultsPagingOptionsType
  ): Observable<PaginatorApiResponse<VaultSideDrawer>> {
    return this.http.get<PaginatorApiResponse<VaultSideDrawer>>(
      this.generatePagingVaultUrl(
        environment.tenantApi +
          `tenant/tenant-id/${tenantId}/vaults/vault-id/${vaultId}/sidedrawers`,
        options
      ),
      {
        headers: this.authService.getHeaders(),
      }
    );
  }

  private generatePagingVaultUrl(
    url: string,
    options: GetVaultsPagingOptionsType
  ): string {
    if (!options) {
      return url;
    }
    const { startingAfter, endingBefore, limit } = options;
    const queryParams: string[] = [];
    if (startingAfter) {
      queryParams.push(
        `startingAfter=${PaginatorHelper.cleanStartingAfterQueryParam(
          startingAfter
        )}`
      );
    }
    if (limit) {
      queryParams.push(`limit=${limit}`);
    }
    if (endingBefore) {
      queryParams.push(`endingBefore=${endingBefore}`);
    }
    if (queryParams.length > 0) {
      url += '?' + queryParams.join('&');
    }
    return url;
  }
}
