import {
  patchState,
  signalStore,
  withHooks,
  withMethods,
  withState,
} from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import {
  removeAllEntities,
  removeEntity,
  setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { VaultSideDrawer } from '../models/vault-side-drawer.type';
import { computed, inject } from '@angular/core';
import { VaultsService } from '../services/vaults.service';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';
import { pipe, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetVaultsPagingOptionsType } from '../models/get-vaults-paging-options.type';
import { tapResponse } from '@ngrx/operators';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  CurrentTenantChange,
  TenantActionsTypes,
} from '../../tenants/store/tenant.actions';

type MultiVaultSideDrawer = VaultSideDrawer & { vaultIds: string[] };

type VaultDrawersState = {
  gettingVaultDrawers: boolean;
  hasMore: boolean;
  nextPage: string;
  previousPage: string;
  tenantId: string;
  vaultId: string;
  totalCount: number;
};

const initialState: VaultDrawersState = {
  gettingVaultDrawers: false,
  hasMore: false,
  nextPage: null,
  previousPage: null,
  tenantId: null,
  vaultId: null,
  totalCount: 0,
};

export const VaultDrawersGlobalStore = signalStore(
  withDevtools('vault-drawers-global'),
  withEntities<MultiVaultSideDrawer>(),
  withState<VaultDrawersState>(initialState),
  withMethods(store => ({
    addOrUpdateVaultDrawer(drawer: VaultSideDrawer) {
      const storedDrawer = store.entityMap()[drawer.id];
      if (storedDrawer) {
        const vaultIds = [...storedDrawer.vaultIds];
        if (!vaultIds.some(vaultId => vaultId === drawer.vault)) {
          vaultIds.push(drawer.vault);
        }
        patchState(
          store,
          updateEntity({ id: drawer.id, changes: { ...drawer, vaultIds } })
        );
        return;
      }
      patchState(store, setEntity({ ...drawer, vaultIds: [drawer.vault] }));
    },
    removeDrawerVault(payload: { vaultId: string; drawerId: string }) {
      const { vaultId, drawerId } = payload;
      const storedDrawer = store.entityMap()[drawerId];
      if (storedDrawer) {
        const vaultIds = [
          ...storedDrawer.vaultIds.filter(id => id !== vaultId),
        ];
        if (vaultIds.length > 0) {
          patchState(
            store,
            updateEntity({ id: drawerId, changes: { vaultIds } })
          );
          return;
        }
        patchState(store, removeEntity(drawerId));
      }
    },
    clear(): void {
      patchState(store, initialState);
      patchState(store, removeAllEntities());
    },
  })),
  withMethods(store => ({
    getDrawersByVaultId(vaultId: string) {
      return computed(() =>
        store
          .entities()
          .filter(drawer => drawer.vaultIds.some(id => id === vaultId))
      );
    },
    getDrawerBySideDrawerId(sideDrawerId: string) {
      return computed(() =>
        store.entities().find(drawer => drawer.sidedrawerId === sideDrawerId)
      );
    },
  })),
  withMethods((store, service = inject(VaultsService)) => ({
    requestDrawersVault: rxMethod<{
      tenantId: string;
      vaultId: string;
      options?: GetVaultsPagingOptionsType;
      callback?: (error?: HttpErrorResponse) => void;
    }>(
      pipe(
        tap(({ tenantId, vaultId }) => {
          if (vaultId !== store.vaultId()) {
            patchState(store, initialState);
          }
          patchState(store, {
            gettingVaultDrawers: true,
            tenantId,
            vaultId,
          });
        }),
        switchMap(({ tenantId, vaultId, options, callback }) =>
          service.getSideDrawersInTenantVault(tenantId, vaultId, options).pipe(
            tapResponse(
              response => {
                const { data, nextPage, previousPage, hasMore, totalCount } =
                  response;
                patchState(store, {
                  gettingVaultDrawers: false,
                  nextPage,
                  previousPage,
                  hasMore,
                  totalCount,
                });
                data.forEach(drawer => store.addOrUpdateVaultDrawer(drawer));
                if (callback) {
                  callback(null);
                }
              },
              (error: HttpErrorResponse) => {
                patchState(store, {
                  gettingVaultDrawers: false,
                });
                if (callback) {
                  callback(error);
                }
              }
            )
          )
        )
      )
    ),
  })),
  withMethods(store => ({
    requestDrawersVaultNextPage(
      callback?: (error?: HttpErrorResponse) => void
    ) {
      if (store.hasMore()) {
        return store.requestDrawersVault({
          tenantId: store.tenantId(),
          vaultId: store.vaultId(),
          options: { startingAfter: store.nextPage() },
          callback,
        });
      }
    },
  })),
  withHooks({
    onInit(store, action$ = inject(Actions)) {
      action$
        .pipe(
          takeUntilDestroyed(),
          ofType<CurrentTenantChange>(TenantActionsTypes.CurrentTenantChange),
          tap(() => store.clear())
        )
        .subscribe();
    },
  })
);
